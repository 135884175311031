export default defineNuxtRouteMiddleware(async (to) => {
  const facilitySearch = useFacilitySearch();

  if (import.meta.client) {
    if(to.fullPath.includes('/search')) {
      facilitySearch.setParams(to.query);

      await useSearch(
        to.query.facility,
        to.query.checkinDate,
        to.query.checkoutDate || ''
      );
      return;
    }
  }
});